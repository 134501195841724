<template>
  <UikitFormFieldCaption
    :message="messageComp"
    error
    class="osk-error-message"
  />
</template>

<script setup lang="ts">
import { useFieldError } from 'vee-validate';

const props = defineProps<{
  name?: string
  message?: string
}>();

const errorMessage = useFieldError(props.name);

const messageComp = computed(() => {
  if (props.message && errorMessage.value) return props.message;

  return errorMessage.value;
});
</script>

<style lang="scss">
@import "~/assets/scss/settings/index";

.osk-error-message {
  flex-shrink: 0;

  & + .osk-field,
  & + .osk-button {
    margin-top: $indent-compact;
  }
}
</style>
