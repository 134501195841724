<template>
  <transition
    name="transition-collapse"
    @enter="enter"
    @after-enter="afterEnter"
    @leave="leave"
  >
    <div
      v-if="show"
      class="osk-transition-collapse"
    >
      <div
        v-if="mb"
        :style="`margin-bottom: ${mb}px`"
      >
        <slot />
      </div>

      <template v-else>
        <slot />
      </template>
    </div>
  </transition>
</template>

<script setup lang="ts">
const props = defineProps<{
  show: boolean
  mb?: number
}>();

function enter(element: HTMLElement) {
  const height = element.scrollHeight;
  const maxHeight = Number(element.style.maxHeight?.replace('px', ''));

  if (maxHeight && height < maxHeight) {
    element.style.height = `${height}px`;
    return;
  }

  element.style.height = element.style.maxHeight || `${height}px`;
}

function afterEnter(element: HTMLElement) {
  element.style.height = 'auto';
}

function leave(element: HTMLElement) {
  const height = element.scrollHeight;
  const maxHeight = Number(element.style.maxHeight?.replace('px', ''));

  if (maxHeight && height < maxHeight) {
    element.style.height = `${height}px`;
  } else {
    element.style.height = element.style.maxHeight || `${height}px`;
  }

  requestAnimationFrame(() => {
    requestAnimationFrame(() => {
      element.style.height = '0';
    });
  });
}
</script>
